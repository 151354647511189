//7e86b327-829a-4ad0-b2f1-57fefc1b20d8

import React, { useEffect, useState } from "react";
import { Link, graphql } from "gatsby";
import BEMHelper from "react-bem-helper";
import styled from 'styled-components';
import Drawer from "@material-ui/core/Drawer";


import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderSolidColor from "../modules/headers/header-solid-color";

import moduleConductor from "../modules/module-conductor";
import ModuleCarousel from "../modules/module-carousel";
import HubspotForm from "../components/hubsport-form";

import { richTextConfig } from "../rich-text-config/rich-text-config";

import articleFallbackImg from "../assets/img/patterns/article-fallback.png";

const bem = new BEMHelper({
  name: "fourohfour",
});

const FourOhFour = ({ data, location, pageContext, ...otherProps }) => {
  const [didSubmit, setDidSubmit] = useState(false);

  // const {
  //   contentfulTemplateListingPage: page,
  //   allContentfulTemplateInsight: insights,
  // } = data;

  // const metaTitle = page.pageTitle ? page.pageTitle : null;
  // const metaDesc = page.seoPageDescription
  //   ? page.seoPageDescription.seoPageDescription
  //   : null;

  // const insightSlugBuilder = (type, slug) => {
  //   if (type === "Newsroom") {
  //     return `/news/${slug}`;
  //   } else {
  //     return `${slug}`;
  //   }
  // };
  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.004;

    for (let x = 0; x < 40; x++) {
      let width = x * 2.5 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = ((x - 20) * (x - 20) * delay) / 8 + "s";
    }
  };

  const { open, onClose, formId, successMessage } = otherProps;
	const configure = (form) => {
		let inputboxs = form.querySelectorAll("input.hs-input")

		inputboxs.forEach(el=>{
			if(el.value!==''){
				el.parentElement.parentElement.classList.add('active')
			}
			el.addEventListener("focus",(e)=>{
				e.target.parentElement.parentElement.classList.add('active')
			})
			el.addEventListener("focusout",(e)=>{
				if(e.target.value==='')
					e.target.parentElement.parentElement.classList.remove('active')
			})
		})
  }
  
  useEffect(() => {
    const loaderWrap = document.getElementById("loader_wrap");
    if(loaderWrap){
      loaderWrap.classList.add("d-none");
      loaderWrap.classList.add("loaded");
    }
    var script = document.createElement("script");
    script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, [])

  return (
    <Layout>
      <SEO
        title={"Update Email Subscription"}
        description={""}
        url={location.href}
      />
      <HeaderSolidColor headerTitle={"Update Email Subscription"} />
      <div {...bem()}>
        <div {...bem("main-content-wrapper")}>
          <article {...bem("body")}>
            <div {...bem("body-copy-wrapper")}>
              {!didSubmit && (
              <HubspotForm
							// ref={formRef}
							portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
              formId={"7e86b327-829a-4ad0-b2f1-57fefc1b20d8"}
							onSubmit={() => setDidSubmit(true)}
							onReady={(form) => {
								configure(form)
							}}
							loading={<div>Loading...</div>}
						/>
              )}
            {didSubmit && (
              <div style={{ margin: '80px 0 400px 0', color: 'black' }}>
                <h2>Preferences updated.</h2>
                <p>Your request has been received and your preferences updated.</p>
              </div>
            )}
            </div>
          </article>
          <aside {...bem("aside")}></aside>
        </div>
      </div>
    </Layout>
  );
};

export default FourOhFour;

// Update: URL should be unitedlex.com/preference-center

// ——
// When possible, please create a page to be used as the “Unsubscribe Page from the Email preferences.”

// Based on the Email subscription preferences, we created the unsubscribe/ manage subscription form.
//  
// Once you create the page, please use the following HubSpot form ID or the below script.
//  
// Manage Email Subscription - HubSpot form ID:
// 7e86b327-829a-4ad0-b2f1-57fefc1b20d8


// <!--[if lte IE 8]>
// <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script>
// <![endif]-->
// <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
// <script>
//   hbspt.forms.create({
//     portalId: "5768467",
//     formId: "7e86b327-829a-4ad0-b2f1-57fefc1b20d8"
// });
// </script>

const FormWrapper = styled.div`
`
